import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import TopNav from './components/TopNav';
import LeftNav from './components/LeftNav';
import MainContent from './components/MainContent';
import DomainManager from './components/DomainManager';
import Sandbox from './components/Sandbox';
import MsDomainOnboard from './components/MsDomainOnboard';
import EmarsysDNS from './components/EmarsysDNS';
import Login from './components/Login';
import LoadingAnimation from './components/LoadingAnimation'; // Import the new component

// Utility function to create a delay
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      console.log('Checking authentication status');
      const authPromise = fetch('/api/user', { credentials: 'include' });
      const delayPromise = delay(1500); // 1.5 seconds delay

      const [response] = await Promise.all([authPromise, delayPromise]);
      const data = await response.json();
      console.log('Authentication status:', data);
      setIsAuthenticated(data.authenticated);
      setUser(data.user);
    } catch (error) {
      console.error('Error checking authentication:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async () => {
    try {
      console.log('Initiating login process');
      const response = await fetch('/api/login');
      console.log('Received response from /api/login');
      const data = await response.json();
      console.log('Parsed response data:', data);
      if (data.auth_url && typeof data.auth_url === 'string') {
        console.log('Redirecting to auth_url:', data.auth_url);
        window.location.href = data.auth_url;
      } else {
        console.error('Login failed: Invalid auth_url', data.auth_url);
        // Handle the error, maybe set an error state
      }
    } catch (error) {
      console.error('Error during login:', error);
      // Handle the error, maybe set an error state
    }
  };

  const logout = async () => {
    try {
      console.log('Initiating logout process');
      await fetch('/api/logout', { credentials: 'include' });
      console.log('Logout successful');
      setIsAuthenticated(false);
      setUser(null);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <Router>
      <div className="App">
        {isAuthenticated ? (
          <>
            <TopNav user={user} onLogout={logout} />
            <div className="content-wrapper">
              <LeftNav />
              <Routes>
                <Route path="/main" element={<MainContent />} />
                <Route path="/domain-manager" element={<DomainManager />} />
                <Route path="/ms-domain-onboard" element={<MsDomainOnboard />} />
                <Route path="/emarsys-dns" element={<EmarsysDNS />} />
                <Route path="/sandbox" element={<Sandbox />} />
                <Route path="*" element={<Navigate to="/main" />} />
              </Routes>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="*" element={<Login onLogin={login} />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
