import React, { useState, useEffect, useRef } from 'react';
import '../styles/TopNav.css';


function TopNav({ onLogout }) {
  const [user, setUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    fetchUser();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchUser = async () => {
    try {
      const response = await fetch('/api/user', { credentials: 'include' });
      const data = await response.json();
      if (data.authenticated) {
        setUser(data.user);
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <nav className="top-nav">
      <div className="logo-container">
        <img src='/images/dmg-events-logo-colour-WHITE-PRINT-Yellowdots.png' alt="Logo" className="logo" />
      </div>
      {user && (
        <div className="user-info" ref={dropdownRef}>
          <button 
            onClick={toggleDropdown} 
            className="account-button"
            aria-expanded={dropdownOpen}
          >
            Account <span className="dropdown-arrow">▼</span>
          </button>
          {dropdownOpen && (
            <div className="dropdown-menu">
              <div className="dropdown-header">
                <strong>LOGGED IN USER</strong>
                <div>{user.username}</div>
              </div>
              <button onClick={onLogout} className="logout-button">Sign Out</button>
            </div>
          )}
        </div>
      )}
    </nav>
  );
}

export default TopNav;
