import React, { useState } from 'react';
import '../styles/Sandbox.css'; // Import the CSS file

function Sandbox() {
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [submitted, setSubmitted] = useState(false); // New state to track submission

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true); // Set submitted to true on form submission
    setName(''); // Clear the name input
    setAge(''); // Clear the age input
  };

  return (
    <div className="sandbox">
      <h2>Sandbox Form</h2>
      {submitted ? ( // Conditional rendering based on submission
        <p>Congratulations!</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Age:
              <input
                type="number"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                required
              />
            </label>
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      )}
    </div>
  );
}

export default Sandbox;
