import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/LeftNav.css';

function LeftNav() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const location = useLocation();

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <nav className="left-nav">
      <ul>
        <li>
          <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
        </li>
        <li>
          <div className={`dropdown ${openDropdown === 'domainManager' ? 'open' : ''}`}>
            <span onClick={() => toggleDropdown('domainManager')} className="nav-link">
              Domain Manager <i className="arrow"></i>
            </span>
            <ul className="dropdown-content">
              <li>
                <Link to="/domain-manager" className={`nav-link ${location.pathname === '/domain-manager' ? 'active' : ''}`}>
                  DNS Records
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className={`dropdown ${openDropdown === 'microsoft' ? 'open' : ''}`}>
            <span onClick={() => toggleDropdown('microsoft')} className="nav-link">
              Microsoft <i className="arrow"></i>
            </span>
            <ul className="dropdown-content">
              <li>
                <Link to="/ms-domain-onboard" className={`nav-link ${location.pathname === '/ms-domain-onboard' ? 'active' : ''}`}>
                  Domain Onboarding
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className={`dropdown ${openDropdown === 'emarsys' ? 'open' : ''}`}>
            <span onClick={() => toggleDropdown('emarsys')} className="nav-link">
              Emarsys <i className="arrow"></i>
            </span>
            <ul className="dropdown-content">
              <li>
                <Link to="/emarsys-dns" className={`nav-link ${location.pathname === '/emarsys-dns' ? 'active' : ''}`}>
                  Domain Onboarding
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <Link to="/sandbox" className={`nav-link ${location.pathname === '/sandbox' ? 'active' : ''}`}>Sandbox</Link>
        </li>
      </ul>
    </nav>
  );
}

export default LeftNav;
