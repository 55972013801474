import React, { useState } from 'react';
import SidebarEmarsysDNS from './SidebarEmarsysDNS';
import '../styles/EmarsysDNS.css';
import { FaPlus, FaCheck, FaCog, FaArrowLeft } from 'react-icons/fa';

function EmarsysDNS() {
  const [domain, setDomain] = useState('');
  const [message, setMessage] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    operation: null,
  });
  const [showResponse, setShowResponse] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  const steps = [
    'Domain',
    'Generate Records',
    'Apply Records',
    'Complete'
  ];

  const setLoading = (isLoading, operation = null) => {
    setLoadingState({ isLoading, operation });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setResponseData(null);
    setLoading(true, 'generating');

    // Show loading for 5 seconds
    await new Promise(resolve => setTimeout(resolve, 5000));

    try {
      const response = await fetch('/api/emarsys_generate_dns_records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain }),
      });

      const data = await response.json();
      setResponseData(data);

      if ('error' in data) {
        setMessage(`There is a problem generating DNS records for ${domain}`);
      } else {
        setMessage(`DNS records generated for ${domain}. Please apply these records.`);
        setCurrentStep(1);
      }
      setShowResponse(true);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setShowResponse(true);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyRecords = async () => {
    setLoading(true, 'applying');
    setMessage('');

    try {
      const response = await fetch('/api/apply_emarsys_dns_records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain }),
      });

      const data = await response.json();

      if (data.success === false) {
        setMessage(data.message || `There was a problem applying DNS records for ${domain}`);
      } else {
        setMessage(data.message || `DNS records applied successfully for ${domain}`);
        setCurrentStep(3);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setDomain('');
    setMessage('');
    setResponseData(null);
    setLoadingState({
      isLoading: false,
      operation: null,
    });
    setShowResponse(false);
    setCurrentStep(0);
  };

  const renderResponseTable = (data) => {
    if (!data || typeof data !== 'object') return null;

    const sections = [
      { title: "Email DNS Records", key: "email_t_records" },
      { title: "Transactional DNS Records", key: "transactional_t_records" },
      { title: "Link Tracking Records", key: "link_tracking_records" }
    ];

    const renderRow = (record, index) => (
      <tr key={index}>
        <td>{record.type || ''}</td>
        <td>{record.name || ''}</td>
        <td>{record.data || ''}</td>
        <td>{record.ttl || ''}</td>
      </tr>
    );

    return (
      <div className="tabbed-response">
        <div className="tab-headers">
          {sections.map((section, index) => (
            <button
              key={index}
              className={`tab-button ${activeTab === index ? 'active' : ''}`}
              onClick={() => setActiveTab(index)}
            >
              {section.title}
            </button>
          ))}
        </div>
        <div className="tab-content">
          <table className="response-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Value</th>
                <th>TTL</th>
              </tr>
            </thead>
            <tbody>
              {data[sections[activeTab].key] && data[sections[activeTab].key].map(renderRow)}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderSpinner = (message, inButton = false) => (
    <div className="emarsys-loading-message">
      <div className="emarsys-loading-spinner"></div>
      {!inButton && <span>{message}</span>}
    </div>
  );

  return (
    <div className="emarsys-dns-container">
      <SidebarEmarsysDNS steps={steps} currentStep={currentStep} />
      <div className="emarsys-dns">
        {currentStep === 3 ? (
          <div className="configuration-complete">
            <h2><FaCheck /> Configuration Complete</h2>
            <p className="success-message">All DNS configuration has been completed for Emarsys.</p>
            <button onClick={handleBack} className="back-button"><FaArrowLeft /> Start Over</button>
          </div>
        ) : showResponse ? (
          <div className="response-view">
            <div className="response-content">
              <h2>DNS Records for {domain}</h2>
              {message && <p className="message">{message}</p>}
              {responseData && (
                <div className="response-data">
                  {renderResponseTable(responseData)}
                </div>
              )}
            </div>
            <div className="action-buttons">
              {currentStep === 1 && (
                <button 
                  onClick={handleApplyRecords} 
                  className="apply-button" 
                  disabled={loadingState.isLoading}
                >
                  {loadingState.isLoading && loadingState.operation === 'applying' ? (
                    <>{renderSpinner("", true)} Applying Records...</>
                  ) : (
                    <><FaCog /> Apply DNS Records</>
                  )}
                </button>
              )}
              <button onClick={handleBack} className="back-button"><FaArrowLeft /> Back to Form</button>
            </div>
          </div>
        ) : (
          <div>
            <h2>Emarsys DNS Configuration</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="domain">Domain:</label>
                <input
                  id="domain"
                  type="text"
                  value={domain} 
                  onChange={(e) => setDomain(e.target.value)}
                  placeholder="Enter domain"
                  required
                  aria-describedby="domainHelp"
                />
                <small id="domainHelp" className="form-text text-muted">Enter the domain for Emarsys DNS configuration.</small>
              </div>
              <button 
                type="submit" 
                className="submit-button" 
                disabled={loadingState.isLoading}
              >
                {loadingState.isLoading && loadingState.operation === 'generating' ? (
                  <>{renderSpinner("", true)} Generating Records...</>
                ) : (
                  <><FaPlus /> Generate DNS Records</>
                )}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmarsysDNS;
