import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../animations/Animation - Loading.json'; // Update this path
import '../styles/LoadingAnimation.css';

function LoadingAnimation() {
  return (
    <div className="loading-container">
      <div className="animation-wrapper">
        <Lottie animationData={animationData} loop={true} />
      </div>
    </div>
  );
}

export default LoadingAnimation;
