import React, { useEffect, useState } from 'react';
import '../styles/Login.css';

function Login({ onLogin }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set a small delay before showing the login box
    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="login-container">
      <div className={`login-box ${isVisible ? 'visible' : ''}`}>
        <h1 className="app-title">ResolveIT</h1>
        <p className="app-description">Streamline IT Support</p>
        <button className="login-button" onClick={onLogin}>
          Sign in with Entra ID
        </button>
      </div>
    </div>
  );
}

export default Login;
