import React, { useState } from 'react';
// import LoadingSpinner from './LoadingSpinner';
import SidebarMsDomainOnboard from './SidebarMsDomainOnboard';
import '../styles/MsDomainOnboard.css';
import { FaPlus, FaCheck, FaCog, FaArrowLeft } from 'react-icons/fa';

function MsDomainOnboard() {
  const [domain, setDomain] = useState('');
  const [message, setMessage] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    operation: null,
  });
  const [showResponse, setShowResponse] = useState(false);
  const [verifyRecord, setVerifyRecord] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [hasConfigRecords, setHasConfigRecords] = useState(false);
  const [configurationComplete, setConfigurationComplete] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    'Domain',  // Changed from 'Onboard Domain' to 'Domain'
    'Verify Domain',
    'Get Service Config',
    'Apply Service Config',
    'Complete'
  ];

  const setLoading = (isLoading, operation = null) => {
    setLoadingState({ isLoading, operation });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setResponseData(null);
    setLoading(true, 'onboarding');

    try {
      const response = await fetch('/api/add_ms_domain', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain }),
      });

      const data = await response.json();
      setResponseData(data);
      console.log(data);

      if ('error' in data) {
        setMessage(`There is a problem adding the domain ${domain}`);
      } else {
        setMessage(`Update the below DNS Verification Records for ${domain}`);
        if (data.value) {
          setVerifyRecord(data.value);
        }
      }
      setShowResponse(true);
      setCurrentStep(1);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      setShowResponse(true);
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async () => {
    setLoading(true, 'verifying');
    setMessage('');

    try {
      const response = await fetch('/api/verify_domain', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain, verify_record: verifyRecord }),
      });

      const data = await response.json();
      setResponseData(data);

      if ('error' in data) {
        setMessage(`There is a problem verifying the domain ${domain}`);
      } else {
        setMessage(`Domain ${domain} verified successfully`);
        setIsVerified(true);
        setCurrentStep(2);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleGetServiceConfig = async () => {
    setLoading(true, 'gettingConfig');
    setMessage('');

    try {
      const response = await fetch('/api/get_service_configuration_records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain }),
      });

      const data = await response.json();
      setResponseData(data);

      if ('error' in data) {
        setMessage(`There is a problem getting service configuration records for ${domain}`);
      } else {
        setMessage(`Service configuration records retrieved for ${domain}`);
        setHasConfigRecords(true);
        setCurrentStep(3);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyServiceConfig = async () => {
    setLoading(true, 'applyingConfig');
    setMessage('');

    try {
      const response = await fetch('/api/apply_service_configuration_records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain, configRecords: responseData }),
      });

      const data = await response.json();
      setResponseData(data);

      if ('error' in data) {
        setMessage(`There is a problem applying service configuration records for ${domain}`);
      } else if (data.success === true) {
        setConfigurationComplete(true);
        setCurrentStep(4);  // Set to the last step (index 4 for a 5-step process)
        setMessage(`Service configuration records applied successfully for ${domain}`);
      } else {
        setMessage(`Unexpected response when applying service configuration records for ${domain}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    // Reset all state variables to their initial values
    setDomain('');
    setMessage('');
    setResponseData(null);
    setLoadingState({
      isLoading: false,
      operation: null,
    });
    setShowResponse(false);
    setVerifyRecord('');
    setIsVerified(false);
    setHasConfigRecords(false);
    setConfigurationComplete(false);
    setCurrentStep(0);
  };

  const renderResponseTable = (data) => {
    if (!data || typeof data !== 'object') return null;

    return (
      <table className="response-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{typeof value === 'object' ? JSON.stringify(value) : value.toString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderSpinner = (message, inButton = false) => (
    <div className="loading-message">
      <div className="loading-spinner"></div>
      {!inButton && <span>{message}</span>}
    </div>
  );

  return (
    <div className="ms-domain-onboard-container">
      <SidebarMsDomainOnboard steps={steps} currentStep={currentStep} />
      <div className="ms-domain-onboard">
        {configurationComplete ? (
          <div className="configuration-complete">
            <h2><FaCheck /> Configuration Complete</h2>
            <p className="success-message">All DNS configuration has been completed. You can now complete the Domain Setup wizard in Office 365 Portal.</p>
            <button onClick={handleBack} className="back-button"><FaArrowLeft /> Start Over</button>
          </div>
        ) : showResponse ? (
          <div className="response-view">
            <h2>Server Response for {domain}</h2>
            {message && <p className="message">{message}</p>}
            {responseData && (
              <div className="response-data">
                {renderResponseTable(responseData)}
              </div>
            )}
            <div className="verification-section">
              {!isVerified ? (
                <button 
                  onClick={handleVerify} 
                  className="verify-button" 
                  disabled={loadingState.isLoading || !verifyRecord}
                >
                  {loadingState.isLoading && loadingState.operation === 'verifying' ? (
                    <>{renderSpinner("", true)} Verifying...</>
                  ) : (
                    <><FaCheck /> Verify Domain</>
                  )}
                </button>
              ) : !hasConfigRecords ? (
                <button 
                  onClick={handleGetServiceConfig} 
                  className="config-button" 
                  disabled={loadingState.isLoading}
                >
                  {loadingState.isLoading && loadingState.operation === 'gettingConfig' ? (
                    <>{renderSpinner("", true)} Getting Config...</>
                  ) : (
                    <><FaCog /> Get Service Config Records</>
                  )}
                </button>
              ) : (
                <button 
                  onClick={handleApplyServiceConfig} 
                  className="apply-config-button" 
                  disabled={loadingState.isLoading}
                >
                  {loadingState.isLoading && loadingState.operation === 'applyingConfig' ? (
                    <>{renderSpinner("", true)} Applying Config...</>
                  ) : (
                    <><FaCog /> Apply Service Config Records</>
                  )}
                </button>
              )}
            </div>
            {loadingState.isLoading && 
              loadingState.operation !== 'verifying' && 
              loadingState.operation !== 'gettingConfig' && 
              loadingState.operation !== 'applyingConfig' && 
              renderSpinner('Processing...')}
            <button onClick={handleBack} className="back-button"><FaArrowLeft /> Back to Form</button>
          </div>
        ) : (
          <div>
            <h2>Add a Domain</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="domain">Domain:</label>
                <input
                  id="domain"
                  type="text"
                  value={domain} 
                  onChange={(e) => setDomain(e.target.value)}
                  placeholder="Enter domain"
                  required
                  aria-describedby="domainHelp"
                />
                <small id="domainHelp" className="form-text text-muted">Enter the domain you want to onboard.</small>
              </div>
              <button 
                type="submit" 
                className="submit-button" 
                disabled={loadingState.isLoading}
              >
                {loadingState.isLoading && loadingState.operation === 'onboarding' ? (
                  <>{renderSpinner("", true)} Processing...</>
                ) : (
                  <><FaPlus /> Use this Domain</>
                )}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default MsDomainOnboard;