import React, { useState } from 'react';
import '../styles/DomainManager.css'; // We'll create this CSS file

function DomainManager() {
  const [domainName, setDomainName] = useState('');
  const [message, setMessage] = useState('');
  const [records, setRecords] = useState([]);
  const [activeTab, setActiveTab] = useState('DNS Records');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('Submitting...');
    setRecords([]);

    try {
      const response = await fetch('/api/submit-domain', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain: domainName }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setMessage(data.message || 'Domain submitted successfully');
      setRecords(data.records || []);
      setDomainName('');
    } catch (error) {
      console.error('Error submitting domain:', error);
      setMessage('Error submitting domain. Please try again.');
      setRecords([]);
    }
  };

  const recordTypes = ['DNS Records', 'A', 'NS', 'CNAME', 'MX', 'TXT', 'CAA'];

  return (
    <div className="domain-manager">
      <h2>Domain Manager</h2>
      <p>Welcome to the Domain Manager section. Enter a domain name to get started.</p>
      
      <form onSubmit={handleSubmit} className="domain-form">
        <div className="form-group">
          <label htmlFor="domainName">Domain Name:</label>
          <input
            type="text"
            id="domainName"
            value={domainName}
            onChange={(e) => setDomainName(e.target.value)}
            placeholder="Enter domain name"
            required
          />
        </div>
        <button type="submit" className="submit-button">Submit Domain</button>
      </form>
      
      {message && <p className="message">{message}</p>}
      
      {records.length > 0 && (
        <div className="records-section">
          <div className="record-tabs">
            {recordTypes.map((type) => (
              <button
                key={type}
                className={`tab-button ${activeTab === type ? 'active' : ''}`}
                onClick={() => setActiveTab(type)}
              >
                {type}
              </button>
            ))}
          </div>
          <table className="records-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Data</th>
                <th>TTL</th>
              </tr>
            </thead>
            <tbody>
              {records
                .filter(record => activeTab === 'DNS Records' || record.type === activeTab)
                .map((record, index) => (
                  <tr key={index}>
                    <td>{record.type}</td>
                    <td>{record.name}</td>
                    <td>{record.data}</td>
                    <td>{record.ttl} seconds</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default DomainManager;
