import React from 'react';
import '../styles/MainContent.css';

function MainContent() {
  return (
    <main className="main-content">
      <div className="logo-container">
        <img 
          src="/images/dmgeventstechnology_light_green.jpg" 
          alt="DMG Events Technology" 
          className="logo-image"
        />
      </div>
    </main>
  );
}

export default MainContent;
