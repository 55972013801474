import React from 'react';
import '../styles/SidebarMsDomainOnboard.css';
import { FaGlobe, FaCheck, FaCog, FaClipboardCheck, FaFlag } from 'react-icons/fa';

const stepIcons = [FaGlobe, FaCheck, FaCog, FaClipboardCheck, FaFlag];

const SidebarMsDomainOnboard = ({ steps, currentStep }) => {
  return (
    <div className="sidebar-ms-domain-onboard">
      {steps.map((step, index) => {
        const isCompleted = index < currentStep;
        const isActive = index === currentStep;
        const isLastStep = index === steps.length - 1;
        const Icon = stepIcons[index];

        return (
          <div 
            key={index} 
            className={`step ${isCompleted ? 'completed' : ''} ${isActive ? 'active' : ''} ${isActive && isLastStep ? 'last-step' : ''}`}
          >
            <div className="step-indicator">
              {isCompleted || (isActive && isLastStep) ? <FaCheck /> : <Icon />}
            </div>
            <div className="step-content">
              <div className="step-name">{step}</div>
              {index < steps.length - 1 && <div className="step-connector"></div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SidebarMsDomainOnboard;
